import logo from './logo.svg';
import Kassa from './components/kassa/Kassa';
import './App.css'
import { Route, Routes } from 'react-router';
import WalletDetail from './components/wallets/WalletDetail';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/'>
          <Route index element={<Kassa/>}/>
          <Route path='dashboard' element={<WalletDetail />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
