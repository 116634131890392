import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import ScanModal from "../modal/ScanModal"
import { walletUrl } from "../../helpers/urls";
import axios from "axios";
import ProductPreview from "./ProductPreview";
import Loader from "../loader/Loader";
import CartList from "./CartList";
import useCards from "../../api/cards";
import {CartContext} from '../../context/CartContext'
import "./Kassa.css"

export default function Kassa() {

  const [status, setStatus] = useState("order");
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [currentCategory, setCurrentCategory] = useState("");
  const [cardnr, setCardnr] = useState("");
  const [error, setError] = useState("");

  const cardsAPI = useCards()
  const {cart, cartTotal, addToCart, removeFromCart, increaseQuantity, decreaseQuantity, clearCart} = useContext(CartContext)

  const handleCloseModal = useCallback(() => {
    setStatus("order");
  }, []);

  const handleConfirmCart = useCallback(()=>{
    setStatus("scan");
  })

  const handleScan = useCallback(({cardnr}) => {
    setCardnr(cardnr)
    setStatus("scanned");
    console.log("Kaart gescand!")
  }, [])

  const changeCategory = useCallback((category) => {
    setCurrentCategory(category)
  });

  useEffect(() => {
    const fetchProuducts = async () => {
      if(status === "order"){
        const response = await axios.get(`${walletUrl}/1/products`)
        const categories = Object.keys(response.data);
        console.log(response.data)
        setData({ categories, products: response.data });
        setCurrentCategory(categories[0]);
        setLoadingData(false)
      }
    }

    fetchProuducts();
  }, [status]);

  useEffect(() => {
    const confirmOrder = async () => {
      if(status === "scanned" && cardnr){
        setStatus("loading");
        console.log("sending order to backend")
        const response = await cardsAPI.createTransaction({
          amount: -cartTotal,
          cardnr,
          products: cart,
        });
    
        console.log(response);
        if (!response.msg) {
          clearCart() // or handle other logic based on your requirements
          setCardnr(null);
          setStatus("success")
        } else {
          // setError(response.msg);
          console.log(response.msg)
          setError(response.msg)
          setStatus("error")
        }
      }
    }
    confirmOrder();
  }, [status, cardnr])

  return <>
  <div className="container mt-4">
    <ScanModal status={status} error={error} onClose={handleCloseModal} onSuccess={handleScan}></ScanModal>
    <div className="row">
      <div className="col-12 mb-3">
        <div className="btn-group d-flex" style={{ flexWrap: 'wrap' }}>
          {data?.categories?.map((category) => (
            <button
                key={category}
                type="button"
                onClick={() => changeCategory(category)}
                className={`btn ${currentCategory === category ? 'btn-primary' : 'btn-secondary'} my-1`}
                style={{ flexGrow: 1, flexBasis: 'calc(50% - 10px)' }} // Pas de breedte aan voor kleine schermen
            >
                {category}
            </button>
          ))}
        </div>
      </div>
      {/* Products Section */}
      <div className="col-12 col-md-8 p-2">
        <div className="row">
          {loadingData ? <Loader/> : <>
          {data?.products[currentCategory]?.map((product) => {
            
            return <ProductPreview
              key={product.productid}
              product={product}
            />
          })}
          </>}
        </div>
      </div>

      {/* Cart Section */}
      <CartList products={cart} onConfirm={handleConfirmCart} />
    </div>
  </div>
  </>
}