import { BsArrowLeftRight, BsBoxSeam, BsGraphUp, BsPeopleFill, BsSpeedometer2 } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { isValidDateValue } from '@testing-library/user-event/dist/utils';

export default function SideBar({ selectedMenuItem, onMenuItemClick }){


  const [visible, setVisible] = useState(true);

  const handleItemClick = useCallback((item) => {
    onMenuItemClick(item);
  }, []);

  const handleButtonClick = useCallback(async () => {
    console.log("click")
    if (window.innerWidth > 992) { // Voer de actie alleen uit voor schermen die smaller of gelijk zijn aan 768 pixels
      setVisible(true); // Verberg de sidebar door de state te wijzigen
    }else{
      setVisible(!visible);
    }
  }, [visible, window.innerWidth])

  return<>
    {visible ?
    <nav id="sideBar" onClick={handleButtonClick} className={`d-lg-block sidebar`}>
      <div className={'position-sticky '}>
        <div className="list-group list-group-flush mx-3 mt-4">
          <p
            className={`no-link list-group-item py-2 ripple clickable  ${selectedMenuItem === 'dashboard' ? 'active text-white' :  ``}`} 
            onClick={() => handleItemClick('dashboard')}>
            <BsSpeedometer2 className="me-2"></BsSpeedometer2><span>Dashboard</span>
          </p>
          <p 
            className={`list-group-item py-2 ripple clickable  ${selectedMenuItem === 'transactions' ? 'active text-white' :  ``}`} 
            onClick={() => onMenuItemClick('transactions')}>
            <BsArrowLeftRight className="me-2"></BsArrowLeftRight><span>Transacties</span>
          </p>
       
          <p 
            className={`list-group-item py-2 ripple clickable ${selectedMenuItem === 'users' ? 'active text-white' :  ``}`} 
            onClick={() => onMenuItemClick('users')}>
            <BsPeopleFill className="me-2"></BsPeopleFill><span>Gebruikers</span>
          </p>
          <p 
          className={`list-group-item py-2 ripple clickable ${selectedMenuItem === 'products' ? 'active text-white' :  ``}`} 
          onClick={() => onMenuItemClick('products')}>
            <BsBoxSeam className="me-2"></BsBoxSeam><span>Producten</span>
          </p>
          <p className={`list-group-item py-2 ripple cursor-default text-muted}`} >
            <BsGraphUp className="me-2"></BsGraphUp><span>Analyse</span>
          </p>
        </div>
      </div> 
    </nav>
    :
    <button className={`btn mb-0`} onClick={handleButtonClick} data-bs-toggle={ visible ? null :  "collapse"} data-bs-target="#sideBar">
      <BsSpeedometer2 size={25} className="me-2"></BsSpeedometer2>
    </button>
    }
  </>
}