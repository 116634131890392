import { Link, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import useWallets from '../../api/wallets';
import Loader from '../loader/Loader';
import Chart from 'react-google-charts';
import TransactionList from '../transactions/TransactionList';

export default function Dashboard({wallet, analyse, transactions, stock}){

  function toList(list){
    let result = [["Product", "Aantal"]];
    for(let i = 0 ; i < list?.length; i++){
      result.push([list[i].name, parseInt(list[i].quantity)])
    }
    console.log(result)
    return result
  }

  const options = {
    pieHole: 0.4,
    legend: "none",
    sliceVisibilityThreshold: .075,
    colors: ['#007BB4', '#009CC4', '#00BBBD', '#29D6A4', '#9BEB84', "#F9F871"],
  };

  return <>
    <div className="main mt-4">
      <div className="container">
        <h1>Dashboard - {wallet.name}</h1>
        <div className="row">
          <div className="col-md-6 col-lg-4 p-2">
            <div className="card shadow bg-gradient-1 text-white border border-0 p-4">
              <h5 className="mb-0">Totaal verdiend:</h5>
              <h1 className="text-end">€{`${transactions?.total?.spent?.toFixed(2)}`}</h1>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 p-2">
            <div className="card shadow bg-gradient-2 text-white border border-0 p-4">
              <h5 className="mb-0">Aantal gebruikers:</h5>
              <h1 className="text-end">{wallet.totalSubs}</h1>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 p-2">
            <div className="card shadow bg-gradient-3 text-white border border-0 p-4">
              <h5 className="mb-0">Aantal transacties:</h5>
              <h1 className="text-end">{wallet.totalTransactions}</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-6 p-2">
            <div className={`card h-100 p-4 shadow no-border`}>
              <h3 data-cy="transaction-overview mb-4">Recente transacties</h3>
              {<TransactionList transactions={transactions.transactions.slice(0,5)}/> }
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-xl-3 p-2">
            <div className={`card h-100 p-4 shadow no-border`}>
              <h3 data-cy="transaction-overview mb-4">Lage voorraad</h3>
              <table className="table table-responsive table-sm table-striped">
                <thead>
                  <tr className={'m-4'}>
                    <th>Product</th>
                    <th>Aantal</th>
                  </tr>
                </thead>
                <tbody>
                {stock?.map(product => {return <><tr key={product.id}>
                  <td>{product.name}</td>
                  <td>{product.quantity}</td>
                </tr></>})}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-xl-3 p-2">
            <div className={`card h-100 p-4 shadow no-border`}>
              <h3>Best verkopend</h3>
              <Chart
                chartType="PieChart"
                width="100%"
                height="100%"
                data={toList(analyse?.sold)}
                options={options}
              />
            </div>
          </div>
        </div>
          
          
        <Link to="register">
          <button className="btn btn-primary w-100 mt-4">Kassa</button>
        </Link>
        <hr></hr>
        
          
        {/* <div className="row">
            <div className="col p-4 text-center">
              <InfoBox color={"blue"} text="Total deposited" number={transactions.total.deposited ? transactions.total.deposited : 0}></InfoBox>
            </div>
            <div className="col p-4 text-center">
              <InfoBox className="col-4" color={"yellow"} text="Total in circulation" number={`${transactions.total.inCirculation}`}></InfoBox>
            </div>
            <div className="col p-4 text-center">
              <InfoBox className="col-4" color={"green"} text="Total spent" number={`${transactions.total.spent}`}></InfoBox>
            </div>
          </div> */}
          
      </div> 
    </div>
  </>;
}