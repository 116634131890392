import { useCallback, useContext, useState } from 'react';
import { BsCartPlus } from 'react-icons/bs';
import { CartContext } from '../../context/CartContext';

export default function ProductPreview({product, onClick}){
  const {name, price, productid} = {...product};

  const [ stockQuantity, setStockQuantity ] = useState(product.stockQuantity);

  const {addToCart} = useContext(CartContext)

  return <>
  {stockQuantity > 0 ? 
    <div className={'col-6 col-sm-6 col-md-4 col-lg-3 clickable p-2'} key={productid}>
      <div className={`card border-0 h-100 shadow rounded clickable`} onClick={() => addToCart(product)} >
        <div className="card-body pb-0">
          <div className="text-center d-flex justify-content-center align-items-center h-100">
            <p>
              <b>{name}</b>
              {stockQuantity <= 5 ? <><br/><span className='text-danger'><small>Nog maar {stockQuantity}!</small></span> </>: null}
            </p>
          </div>
        </div>
        <div className={`card-footer text-center`}>
          €{price.toFixed(2)}
        </div>
      </div>
    </div>
    : null }
  </>;
}