const baseUrl =  process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_API_URL}` : `${process.env.REACT_APP_API_URL_PRODUCTION}`;


const categorieUrl = `${baseUrl}/categories`
const walletUrl = `${baseUrl}/wallets`
const cardsUrl =  `${baseUrl}/cards`


module.exports = {
  baseUrl,
  categorieUrl,
  walletUrl,
  cardsUrl
}