import { useCallback, useContext, useState } from 'react';
import { BsCartPlus, BsThreeDots, BsTrash } from 'react-icons/bs';
import { CartContext } from '../../context/CartContext';

export default function CartItem({product}){
  const {productid, name, price, quantity} = {...product};

  console.log(productid)

  const {removeFromCart, increaseQuantity, decreaseQuantity} = useContext(CartContext)

  return <>
    <div className={'col-12 p-2'} key={name}>
      <div className={`card shadow-sm rounded`} >
        <div className="card-body px-4">
          <div className="d-flex justify-content-between">
            <p><b>{name}</b></p>
            <BsTrash className="clickable" onClick={() => removeFromCart(productid)} color="#C84758"></BsTrash>
          </div>
          
          <div className="d-block text-center d-md-flex justify-content-between align-items-center">
            <p className="text-primary mb-2 mb-md-0">€{price.toFixed(2)}</p>
            <div className="d-flex align-items-center">
              <button onClick={() => decreaseQuantity(productid)} className="btn mx-2 btn-quantity btn-outline-secondary rounded">-</button>
              <p className='mb-0 mx-4'>{quantity}</p>
              <button onClick={() => increaseQuantity(productid)} className="btn mx-2 btn-quantity btn-primary rounded">+</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}