import axios from 'axios';
import {
  useCallback,
} from 'react';
import { cardsUrl } from '../helpers/urls';

const useCards = () => {

  const createTransaction = useCallback(async ({amount, cardnr, products}) => {
    console.log(products);
    const response = await axios({
      method: 'POST',
      url: `${cardsUrl}/${cardnr}/transactions`, 
      data: {amount, products}
    });
 
    return response.data;
  }, []);

  return {
    createTransaction,
  };
};

export default useCards;