import { BsCheck, BsX } from "react-icons/bs";
import CardScanner from "../kassa/CardScanner";
import { useCallback } from "react";
import Loader from "../loader/Loader";

export default function ScanModal({
  status,
  onClose,
  onSuccess,
  onRetry,
  error
}){

  const handleSuccess = useCallback(async ({cardnr}) => {
    console.log(cardnr)
    onSuccess({cardnr})
  }, [])

  if(status === "loading"){
    return <>
    <div className={`modal-overlay`}>
      <div className="modal-content">
        <span className="modal-close" onClick={() => onClose()}>&times;</span>
        <h1>Scan de kaart!</h1>
        <Loader loading={true} />
      </div>
    </div> 
    </>
  }

  if(status === "order"){
    return <></>
  }

  if(status === "error"){
    return <>
      <div className={`modal-overlay error-modal`}>
        <div className="modal-content">
          <span className="modal-close" onClick={() => onClose()}>&times;</span>
          <BsX size={50} color="red" className="align-self-center" />
          <h1 className="text-center">Transactie mislukt!</h1>
          <p className="text-center text-danger">{error}</p>
          <button onClick={() => onRetry()} className="btn btn-outline-dark mt-4" >Scan Opnieuw!</button>
        </div>
      </div>
    </>
  }

  if(status === "success"){
    return <>
      <div className={`modal-overlay success-modal`}>
        <div className="modal-content">
          <span className="modal-close" onClick={() => onClose()}>&times;</span>
          <BsCheck size={50} color="green" className="align-self-center" />
          <h1 className="text-center">Transactie geslaagd!</h1>
          <button onClick={() => onClose()} className="btn btn-outline-dark mt-4" >Klaar!</button>
        </div>
      </div>
    </>
  }

  return <>
    <div className={`modal-overlay`}>
      <div className="modal-content">
        <span className="modal-close" onClick={() => onClose()}>&times;</span>
        <h1>Scan de kaart!</h1>
        <CardScanner onSuccess={handleSuccess}/>
      </div>
    </div>

  </>
}