import React from "react";
import { QrReader } from "react-qr-reader";
import { BsCheck, BsX } from "react-icons/bs";

export default function CardScanner({onSuccess}) {
  const handleScan = (result, error) => {
    if (!!result) {
      console.log(result.text);
      onSuccess({ cardnr: result.text });
    }
  };

  return (
    <QrReader
      onResult={handleScan}
      style={{ width: "100%" }}
      constraints={{ facingMode: "environment" }}
    />
  );
}

