import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useWallets from '../../api/wallets';
import SideBar from './SideBar';
import Dashboard from './Dashboard';


export default function WalletDetail(){

  const compId = 1;
  const walletId = 1;
  const [ wallet, setWallet] = useState({});
  const [ authorized, setAuthorized ] = useState(false);
  const [ transactions, setTransactions ] = useState({transactions: [], total : 0});
  const [ loadingWallet, setLoadingWallet ] = useState(true);
  const [ stock, setStock ] = useState();
  const [ loadingStock, setLoadingStock ] = useState(true);
  const [ loadingTransactions, setLoadingTransactions ] = useState(true);
  const [ month, setMonth ] = useState(new Date().getMonth() + 1);
  const [ year, setYear ] = useState(new Date().getFullYear());
  const walletAPI = useWallets();

  const [ analyse, setAnalyse ] = useState();
  const [ loadingAnalyse, setLoadingAnalyse ] = useState(true);

  const [ users, setUsers ] = useState([]);
  const [ loadingUsers, setLoadingUsers ] = useState(true);

  const [ selectedMenuItem, setSelectedMenuItem] = useState('dashboard');

  useEffect(() => {
    async function fetchTransactions(){
      try{
        console.log(`compId: ${compId}`)
        console.log(`walletId: ${walletId}`)
        const result = await walletAPI.getTransactionsOfWallet({compId, walletId, month, year});
        console.log(`transactions: ${result}`)
        setTransactions(result);
        setLoadingTransactions(false);
      }catch(error){
        console.log(error);
        throw new Error(error);
      }
    }

    async function fetchWallet(){
      try{
        const result = await walletAPI.getById({compId, walletId, month, year});
        setWallet(result);
        setLoadingWallet(false);
      }catch(error){
        console.log(error);
        throw new Error(error);
      }
    }

    async function fetchStock(){
      try{
        const result = await walletAPI.getStockOfWallet({walletId, limit: 5});
        setStock(result.stock);
        setLoadingStock(false);
      }catch(error){
        console.log(error);
        throw new Error(error);
      }
    }

    const fetchAnalyse = async () => {
      try{
        const result  = await walletAPI.getAnalytics({walletId, month, year});
        console.log(result);
        setAnalyse(result);
        setLoadingAnalyse(false);
      }catch(error){

      }
    }
    fetchAnalyse()

    fetchTransactions();
    fetchWallet();
    fetchStock();
  }, []);

  return <>
    <SideBar
      selectedMenuItem={selectedMenuItem} 
      onMenuItemClick={(menuItem) => setSelectedMenuItem(menuItem)}></SideBar>
    <Dashboard
      analyse={analyse} 
      wallet={wallet} 
      stock={stock} 
      transactions={transactions} 
    >
    </Dashboard>
  </>;
}