import { useCallback, useContext, useMemo } from "react";
import CartItem from "./CartItem";
import { CartContext } from "../../context/CartContext";

export default function CartList({
  onConfirm
}){

  const {cartTotal, cart} = useContext(CartContext)
  
  const handleClickConfirm = useCallback(() => {
    onConfirm("scan")
  }, [])

  return <>

    <div className="col-12 col-md-4 p-2">
      <div className={`shadow-lg rounded p-4 d-flex flex-column`} style={{ maxHeight: '80vh', height: '700px' }}>
        <h3>Mijn bestelling</h3>
        <div className="overflow-auto" style={{ maxHeight: '50vh' }}>
          {cart.map((product) => (
            <CartItem
              key={product.productid}
              product={product}
            />
          ))}
        </div>
        <div className="mt-auto">
          <div className="d-flex justify-content-between">
            <span>Totaal:</span>
            <strong>€{cartTotal.toFixed(2)}</strong>
          </div>
          <button 
            disabled={cart?.length <= 0} 
            className="btn btn-primary w-100 align-self-end"
            onClick={handleClickConfirm}>
              Bevestig
          </button>
        </div>
      </div>
    </div>
  </>
}