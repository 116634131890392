export default function Loader({ loading }) {
  if (loading) {
    return (
      <>
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <div>
            <div className="spinner-border center">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
}