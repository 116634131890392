// CartContext.js
import React, { createContext, useMemo, useState } from 'react';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const cartTotal = useMemo(() => 
    cart.reduce((total, item) => total + item.quantity * item.price, 0), [cart]);

    const findItemIndexById = (productId) => {
      return cart.findIndex((cartItem) => cartItem.productid === productId);
    };
  
    const addToCart = (item) => {
      const existingItemIndex = findItemIndexById(item.productid);
  
      if (existingItemIndex !== -1) {
        // Item bestaat al in het winkelmandje, verhoog de hoeveelheid
        increaseQuantity(item.productid);
      } else {
        // Voeg een nieuw item toe aan het winkelmandje
        setCart([...cart, { ...item, quantity: 1 }]);
      }
    };
  
    const removeFromCart = (productId) => {
      const itemIndex = findItemIndexById(productId);
      if (itemIndex !== -1) {
        const newCart = [...cart];
        newCart.splice(itemIndex, 1);
        setCart(newCart);
      }
    };
  
    const increaseQuantity = (productId) => {
      const itemIndex = findItemIndexById(productId);
      if (itemIndex !== -1) {
        const newCart = [...cart];
        newCart[itemIndex].quantity += 1;
        setCart(newCart);
      }
    };
  
    const decreaseQuantity = (productId) => {
      const itemIndex = findItemIndexById(productId);
      if (itemIndex !== -1) {
        const newCart = [...cart];
        if (newCart[itemIndex].quantity > 1) {
          newCart[itemIndex].quantity -= 1;
        } else {
          newCart.splice(itemIndex, 1);
        }
        setCart(newCart);
      }
    };
  
    const clearCart = () => {
      setCart([]);
    };

  return (
    <CartContext.Provider
      value={{ cart, cartTotal, addToCart, removeFromCart, increaseQuantity, decreaseQuantity, clearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
